import { Table } from "antd";
import { decoderStatusTrackTableColumns } from "./helper";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import { downloadBase64File, tableLoading } from "../../utils/utils";
import API from "../../utils/api/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/reducers";
import { notificationTypes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

export const DecoderStatusTrackSheetTable = ({
  backStep,
  nextStep,
  handleOpenSheet,
  uploadDecoderSheet,
  decoderTrackerData,
  companyName,
  projectId,
  redirect,
  editAllowed
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exportDecoderSheet = async () => {
    const url = "v1/decoder/" + projectId + "/decoder/download";
    const response = await API({
      method: "GET",
      path: url,
      dispatch,
    });
    const file = downloadBase64File(response?.data);
    if (file) {
      dispatch(
        setNotification({
          type: notificationTypes.SUCCESS,
          message: "File Downloaded Successfully",
          placement: "bottom",
        })
      );
    }
  };
  const finishDecoderProcess = () => {
    navigate("/");
    dispatch(
      setNotification({
        type: notificationTypes.SUCCESS,
        message: Aries.DECODER_TOAST_FINALIZE_SUCCESS,
        placement: "bottom",
      })
    );
  };
  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_DOWNLOAD_FINALIZED_OUTPUT,
        className: "white-button",
        fn: () => exportDecoderSheet(),
        imageLink: images["download.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
        disabled: redirect,
      },
      {
        text: Aries.BUTTON_GLOBAL_FINISH,
        className: "primary-button",
        fn: () => finishDecoderProcess(),
        disabled: !decoderTrackerData?.finishEnabled,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };
  return (
    <>
      <div className="decoder-track-header">
        <div className="decoder-track-header-title">Financials Decoder</div>
        <div className="decoder-engament-text">
          {" "}
          Engagement: <span>{companyName}</span>
        </div>
      </div>
      <div className="decoder-track-table-wrapper">
        <div className="decoder-track-heading-wrapper">
          <h2>Balance Sheets</h2>
          <div className="button-group-wrapper">
            <button
              className="button primary-button"
              onClick={() => uploadDecoderSheet()}
              disabled={!editAllowed}
            >
              Upload Current Period Financials
            </button>
            <button className="button primary-button" disabled>
              Check SEC for more files
            </button>
          </div>
        </div>
        <div className="decoder-track-table">
          <Table
            dataSource={decoderTrackerData?.balanceSheets}
            columns={decoderStatusTrackTableColumns(
              handleOpenSheet,
              "BALANCE_SHEET"
            )}
            pagination={false}
            rowHoverable={false}
            className={"policies-table table-container policy-table"}
            loading={tableLoading({
              loading: decoderTrackerData ? false : true,
              text: Aries.DECODER_TRACKER_LOADER,
            })}
            rowKey={(record) => record.docId}
            scroll={{ x: "1230px" }}
          />
        </div>
        <div className="decoder-track-heading-wrapper">
          <h2>Income Statements</h2>
        </div>
        <div className="decoder-track-table">
          <Table
            dataSource={decoderTrackerData?.incomeStatements}
            columns={decoderStatusTrackTableColumns(
              handleOpenSheet,
              "INCOME_STATEMENT"
            )}
            pagination={false}
            rowHoverable={false}
            className={"table-container"}
            loading={tableLoading({
              loading: decoderTrackerData ? false : true,
              text: Aries.DECODER_TRACKER_LOADER,
            })}
            rowKey={(record) => record.docId}
            scroll={{ x: "1230px" }}
          />
        </div>
      </div>
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </>
  );
};
