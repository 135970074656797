import { Modal } from "antd";
import { setError } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import images from "../images/images";
import "./modelStyle.scss";
import Aries from "../../locale/en-Us.json";
import { CloseIcon } from "../../utils/iconComponents";
import {
  alertDataTypes,
  EMPTY_GENERIC_ERROR_DATA,
  notificationTypes,
} from "../../utils/constants";

const ErrorModel = () => {
  const dispatch = useDispatch();
  const errorData = useSelector((state) => state.errorData);

  const hideErrorModal = () => {
    dispatch(setError(EMPTY_GENERIC_ERROR_DATA));
  };

  const getIcon = () => {
    switch (errorData?.[alertDataTypes.TYPE]) {
      case notificationTypes.ERROR:
        return images["status-error-icon.svg"];
      case notificationTypes.WARNING:
        return images["warning-state.svg"];
      case notificationTypes.INFO:
        return images["status-info-icon.svg"];
      default:
        return images["status-error-icon.svg"];
    }
  };

  return (
    <Modal
      open={errorData.error}
      onCancel={hideErrorModal}
      footer={
        <button className="primary-button button" onClick={hideErrorModal}>
          {errorData?.[alertDataTypes.PRIMARY_BUTTON_TEXT] || "Okay"}
        </button>
      }
      closeIcon={<CloseIcon />}
      centered
      className="error-modal"
    >
      <div className="error-modal-wrapper">
        <div className="model-status-icon">
          <img src={getIcon()} alt={errorData?.[alertDataTypes.TYPE]} />
        </div>
        <div className="modal-text-wrapper">
          <span className="model-status-title">
            {errorData?.[alertDataTypes.TITLE] || Aries.ERROR_HEADING_MESSAGE}
          </span>
          <span className="model-status-desc">
            {errorData?.[alertDataTypes.MESSAGE]}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModel;
