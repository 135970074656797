import { LoadingOutlined } from "@ant-design/icons";
import { Select, Skeleton, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import images from "../../components/images/images";
import SheetComponent from "../../components/sheetComponent";
import Aries from "../../locale/en-Us.json";
import { DURATION_MAP, SHEET_NAME_MAP, UNITSMAP } from "./constant";

export const DisplaySheetWithDetails = ({
  label,
  nextStep,
  backStep,
  sheetGuidance,
  sheetName,
  file,
  submitSheetguidance,
  statementType,
  selectedSheetIndex,
  checkIsDetailsEdited,
}) => {
  const spreadsheetRef = useRef(null);
  const divRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [arrayIndex, setArrayIndex] = useState(0);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [additionalDates, setAdditionalDates] = useState([]);
  const [sheetDetailsArray, setSheetDetailsArray] = useState([
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.text || "",
      rowIdx:
        sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.rowIdx === 0
          ? "0"
          : sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.rowIdx || "",
      cellIdx:
        sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.cellIdx === 0
          ? "0"
          : sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.cellIdx || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.date1?.text || "",
      rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date1?.rowIdx || "",
      cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date1?.cellIdx || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.date2?.text || "",
      rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date2?.rowIdx || "",
      cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date2?.cellIdx || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.date3?.text || "",
      rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date3?.rowIdx || "",
      cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date3?.cellIdx || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.date4?.text || "",
      rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date4?.rowIdx || "",
      cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date4?.cellIdx || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.duration1 || "",
    },
    {
      text: sheetGuidance?.[sheetName[currentIndex]]?.duration2 || "",
    },
  ]);
  const [sheetGuidanceData, setSheetGuidanceData] = useState(null);
  const [updatedSheetGuidance, setUpdatedSheetGuidance] =
    useState(sheetGuidance);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (sheetName.length < 1) {
      backStep();
    }
    setUpdatedSheetGuidance(sheetGuidance);
    setSheetGuidanceData(sheetGuidance?.[sheetName[currentIndex]]);
    setSheetDetailsArray([
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.text || "",
        rowIdx:
          sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.rowIdx === 0
            ? "0"
            : sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.rowIdx ||
              "",
        cellIdx:
          sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.cellIdx === 0
            ? "0"
            : sheetGuidance?.[sheetName[currentIndex]]?.tableStart?.cellIdx ||
              "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.date1?.text || "",
        rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date1?.rowIdx || "",
        cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date1?.cellIdx || "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.date2?.text || "",
        rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date2?.rowIdx || "",
        cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date2?.cellIdx || "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.date3?.text || "",
        rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date3?.rowIdx || "",
        cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date3?.cellIdx || "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.date4?.text || "",
        rowIdx: sheetGuidance?.[sheetName[currentIndex]]?.date4?.rowIdx || "",
        cellIdx: sheetGuidance?.[sheetName[currentIndex]]?.date4?.cellIdx || "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.duration1 || "",
      },
      {
        text: sheetGuidance?.[sheetName[currentIndex]]?.duration2 || "",
      },
    ]);
  }, [currentIndex, sheetGuidance, sheetName]);

  const getHandleIndexValue = (index, args, arrayValue) => {
    const rowIndex = parseInt(index[1]);
    const cellIndex = parseInt(index[0]);
    const text = arrayValue;

    if (isNaN(rowIndex) || isNaN(cellIndex) || arrayIndex == null) {
      return;
    }
    const newArrayDetails = [...sheetDetailsArray];
    if (arrayIndex === 2) {
      handleScroll();
    }
    if (text[rowIndex]) {
      if (arrayIndex >= 0 && arrayIndex < newArrayDetails.length) {
        newArrayDetails[arrayIndex] = {
          ...newArrayDetails[arrayIndex],
          cellIdx: rowIndex === 0 ? "0" : rowIndex,
          rowIdx: cellIndex,
          text: text[rowIndex],
        };
        checkIsDetailsEdited();
        setArrayIndex(arrayIndex + 1, "arrayIndex");
        setSheetDetailsArray(newArrayDetails);
        setUpdatedSheetGuidance((prevSheetGuidance) => {
          const newSheetGuidance = {
            ...prevSheetGuidance,
            [sheetName[currentIndex]]: {
              ...prevSheetGuidance[sheetName[currentIndex]],
              tableStart: newArrayDetails[0],
              date1: newArrayDetails[1],
              date2: newArrayDetails[2],
              ...(newArrayDetails[arrayIndex]?.text && {
                [`date${arrayIndex}`]: newArrayDetails[arrayIndex],
              }),
            },
          };
          submitSheetguidance(newSheetGuidance);
          return newSheetGuidance;
        });
      } else {
        console.error("Index exceeds array length:", arrayIndex);
      }
    }
  };
  const addNewDateField = (sheetType) => {
    if (sheetType === "BALANCE_SHEET") {
      setAdditionalDates([...additionalDates, { date: "" }]);
    } else {
      setAdditionalDates([...additionalDates, { date: "", duration: "" }]);
    }
  };

  const updateAdditionalDate = (index, field, value) => {
    const updatedDates = [...additionalDates];
    updatedDates[index][field] = value;
    setAdditionalDates(updatedDates);
    setUpdatedSheetGuidance((prevSheetGuidance) => {
      const newSheetGuidance = {
        ...prevSheetGuidance,
        [sheetName[currentIndex]]: {
          ...prevSheetGuidance[sheetName[currentIndex]],
          [`duration${index + 1}`]: value,
        },
      };
      submitSheetguidance(newSheetGuidance);
      return newSheetGuidance;
    });
  };
  const handleScroll = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const changeUnitType = (unit) => {
    if (unit)
      setUpdatedSheetGuidance((prevSheetGuidance) => {
        const newSheetGuidance = {
          ...prevSheetGuidance,
          [sheetName[currentIndex]]: {
            ...prevSheetGuidance[sheetName?.[currentIndex]],
            units: unit,
          },
        };
        submitSheetguidance(newSheetGuidance);
        return newSheetGuidance;
      });
    checkIsDetailsEdited();
  };

  const handleDuration = (data, feild) => {
    if (data)
      setUpdatedSheetGuidance((prevSheetGuidance) => {
        const newSheetGuidance = {
          ...prevSheetGuidance,
          [sheetName[currentIndex]]: {
            ...prevSheetGuidance[sheetName?.[currentIndex]],
            [feild]: data,
          },
        };
        submitSheetguidance(newSheetGuidance);
        return newSheetGuidance;
      });
    checkIsDetailsEdited();
  };
  const validateForm = () => {
    const errors = {};
    const currentSheet = updatedSheetGuidance[sheetName[currentIndex]];

    if (!currentSheet.units) {
      errors.units = "*Please select a unit";
    }
    if (!currentSheet.date1?.text) {
      errors.date1 = "*Please select a Date";
    }
    if (statementType !== "BALANCE_SHEET") {
      if (!currentSheet.date2?.text) {
        errors.date2 = "*Please select a Date";
      }
    }
    if (statementType !== "BALANCE_SHEET") {
      if (!currentSheet.duration1) {
        errors.duration1 = "*Please select a duration";
      }

      // Validate additional dates and durations
      let dateIndex = 3;
      while (currentSheet[`date${dateIndex}`]?.text) {
        if (!currentSheet[`duration${dateIndex - 1}`]) {
          errors[`duration${dateIndex - 1}`] = "*Please select a duration";
        }
        dateIndex++;
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmitGuidance = () => {
    setUpdatedSheetGuidance((prevSheetGuidance) => {
      const newSheetGuidance = {
        ...prevSheetGuidance,
        [sheetName[currentIndex]]: {
          ...prevSheetGuidance[sheetName[currentIndex]],
          statementType: statementType,
        },
      };
      submitSheetguidance(newSheetGuidance);
      nextStep();
      return newSheetGuidance;
    });
    // checkIsDetailsEdited();
    setIsLoading(false);
    setArrayIndex(0);
  };
  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        disabled: isLoading,
        className: "back-button",
        fn: () => {
          if (currentIndex !== 0) {
            setIsLoading(true);
            setTimeout(() => {
              // setCurrentIndex(currentIndex - 1);
              // handleChangeSheet(currentIndex - 1);
              setArrayIndex(0);
            }, 1000);

            setTimeout(() => {
              setIsLoading(false);
            }, 3000);
          } else {
            backStep();
          }
        },
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        disabled: isLoading || disabledNextButton,
        className: "primary-button",
        fn: () => {
          if (validateForm()) {
            setIsLoading(true);
            handleSubmitGuidance();
          }
        },
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };
  const loadingsheetStatus = (response) => {
    setTimeout(() => {
      setDisabledNextButton(response);
    }, 2000);
  };

  const activeSheet = () => {
    if (spreadsheetRef.current) {
      const sheetArray = spreadsheetRef.current.sheets.map((item) => item.name);
      spreadsheetRef.current.activeSheetIndex = sheetArray.indexOf(
        sheetName[0]
      );
    }
  };

  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
        fullscreen
        className="fullscreen-loader"
      />
      <div className="list-file-container">
        <div className="wrapper-label-decoder">
          <img
            className="user-icon"
            src={images["aistra-vaa-avatar-1.svg"]}
            loading="lazy"
            alt="User icon"
          />
          <div
            className="label"
            dangerouslySetInnerHTML={{ __html: label }}
          ></div>
        </div>
        <div className="entity-name">Sheet: {sheetName?.[currentIndex]} </div>
        <div className="decoder-display-container">
          <div className="sheet-left-pannel">
            <SheetComponent
              file={file}
              showBottomTabs={false}
              sheetIndex={selectedSheetIndex}
              spreadsheetRef={spreadsheetRef}
              enableVirtualization={false}
              getCellIndexValue={getHandleIndexValue}
              className="decoder-sheet"
              loadingMessage="Preparing document..."
              activeSheet={activeSheet}
              loadingsheetStatus={loadingsheetStatus}
            />
          </div>

          <div className="sheet-right-pannel" ref={divRef}>
            {!sheetGuidanceData ||
              (disabledNextButton && (
                <>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </>
              ))}
            {sheetGuidanceData && !disabledNextButton && (
              <div className="decoder-form">
                <div className="decoder-input">
                  <div className="input-label">Entity Name:</div>
                  <div className="input-value">
                    {sheetGuidanceData?.entityName}
                  </div>
                </div>
                {/* <div className="decoder-input">
                  <div className="input-label">Statement Types:</div>
                  <div className="decoder-select-wrap">
                    {" "}
                    <Select
                      defaultValue={"BALANCE_SHEET"}
                      value={
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]
                          ?.statementType
                      }
                      onChange={changeStatmentType}
                      className="decoder-select"
                      options={SHEET_NAME_MAP}
                      id="1"
                      status={validationErrors.statementType ? "error" : ""}
                    />
                    {validationErrors.statementType && (
                      <div className="error-message">
                        {validationErrors.statementType}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="decoder-input">
                  <div className="input-label">Units:</div>
                  <div className="decoder-select-wrap">
                    {" "}
                    <Select
                      placeholder="Select Unit"
                      optionFilterProp="label"
                      defaultValue={
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]?.units
                      }
                      value={
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]?.units
                      }
                      onChange={changeUnitType}
                      className="decoder-select"
                      options={UNITSMAP}
                      id="2"
                      status={validationErrors.units ? "error" : ""}
                    />
                    {validationErrors.units &&
                      !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                        ?.units && (
                        <div className="error-message">
                          {validationErrors.units}
                        </div>
                      )}
                  </div>
                </div>
                <div className="decoder-input">
                  <div className="input-label">
                    {statementType === "BALANCE_SHEET"
                      ? "Select first cell of the balance sheet:"
                      : "Select first cell of the income statement:"}
                  </div>
                  <div
                    className={`input-value ${arrayIndex === 0 ? "active-feild" : ""}`}
                    onClick={() => setArrayIndex(0)}
                  >
                    {
                      updatedSheetGuidance?.[sheetName?.[currentIndex]]
                        ?.tableStart?.text
                    }
                  </div>
                </div>
                <div className="decoder-input">
                  <div className="input-heading">
                    Click on date cells to select current date and previous
                    period date:
                  </div>
                </div>
                <div className="decoder-input">
                  <div
                    className={`input-value input-date ${arrayIndex === 1 ? "active-feild" : ""}`}
                    onClick={() => {
                      setArrayIndex(1);
                      handleScroll();
                    }}
                  >
                    {updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date1
                      ?.text || (
                      <span className="date-placeholder">
                        {"End-date current period "}
                      </span>
                    )}
                    <img src={images["calendar-ant.svg"]} alt="calender" />
                  </div>

                  {statementType !== "BALANCE_SHEET" && (
                    <div className="decoder-select-wrap">
                      {" "}
                      <Select
                        value={
                          updatedSheetGuidance?.[sheetName?.[currentIndex]]
                            ?.duration1
                        }
                        disabled={statementType === "BALANCE_SHEET"}
                        onChange={(e) => handleDuration(e, "duration1")}
                        className="decoder-select"
                        placeholder="Comparison period"
                        optionFilterProp="label"
                        options={DURATION_MAP}
                        id="3"
                        status={validationErrors.duration1 ? "error" : ""}
                      />
                      {/* {validationErrors.duration1 &&
                      !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                        ?.duration1 && (
                        <div className="error-message">
                          {validationErrors.duration1}
                        </div>
                      )} */}
                    </div>
                  )}
                </div>
                <div className="multiple-error">
                  {validationErrors.date1 &&
                    !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                      ?.date1 && (
                      <div className="error-message">
                        {validationErrors.date1}
                      </div>
                    )}
                  {validationErrors.duration1 &&
                    statementType !== "BALANCE_SHEET" &&
                    !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                      ?.duration1 && (
                      <div className="error-message">
                        {validationErrors.duration1}
                      </div>
                    )}
                </div>
                <div className="decoder-input">
                  <div
                    className={`input-value input-date ${arrayIndex === 2 ? "active-feild" : ""}`}
                    onClick={() => setArrayIndex(2)}
                  >
                    {updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date2
                      ?.text || (
                      <span className="date-placeholder">
                        {"End-date past period "}
                      </span>
                    )}
                    <img src={images["calendar-ant.svg"]} alt="calender" />
                  </div>
                  {statementType !== "BALANCE_SHEET" && (
                    <div className="decoder-select-wrap">
                      {" "}
                      <Select
                        placeholder="Comparison period"
                        optionFilterProp="label"
                        disabled={statementType === "BALANCE_SHEET"}
                        value={
                          updatedSheetGuidance?.[sheetName?.[currentIndex]]
                            ?.duration1
                        }
                        className="decoder-select"
                        onChange={(e) => handleDuration(e, "duration1")}
                        options={DURATION_MAP}
                        id="4"
                        status={validationErrors.duration1 ? "error" : ""}
                      />
                    </div>
                  )}
                </div>
                <div className="multiple-error">
                  {validationErrors.date2 &&
                    !updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date2
                      ?.text && (
                      <div className="error-message">
                        {validationErrors.date2}
                      </div>
                    )}
                </div>
                {additionalDates.map((date, index) => (
                  <div key={index} className="decoder-input">
                    <div
                      className={`input-value input-date ${arrayIndex === 3 + index ? "active-feild" : ""}`}
                      onClick={() => setArrayIndex(3 + index)}
                    >
                      {updatedSheetGuidance?.[sheetName?.[currentIndex]]?.[
                        `date${3 + index}`
                      ]?.text || (
                        <span className="date-placeholder">
                          {"End-date past period "}
                        </span>
                      )}
                      <img src={images["calendar-ant.svg"]} alt="calendar" />
                    </div>
                    {statementType !== "BALANCE_SHEET" && (
                      <div className="decoder-select-wrap">
                        <Select
                          placeholder="Comparison period"
                          optionFilterProp="label"
                          disabled={statementType === "BALANCE_SHEET"}
                          value={
                            updatedSheetGuidance?.[
                              sheetName?.[currentIndex][`duration${2 + index}`]
                            ]
                          }
                          className="decoder-select"
                          onChange={(e) =>
                            handleDuration(e, `duration${2 + index}`)
                          }
                          // onChange={(e) => updateAdditionalDate(1 + index, 'duration', e)}
                          options={DURATION_MAP}
                          status={
                            validationErrors[`additionalDuration${2 + index}`]
                              ? "error"
                              : ""
                          }
                        />
                      </div>
                    )}

                    <div className="multiple-error">
                      {validationErrors[`additionalDate${index}`] && (
                        <div className="error-message">
                          {validationErrors[`additionalDate${index}`]}
                        </div>
                      )}
                      {validationErrors[`additionalDuration${index}`] && (
                        <div className="error-message">
                          {validationErrors[`additionalDuration${index}`]}
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                {/* Add button for new date fields */}
                <div className="custom-decoder-sheet-btn-wrap">
                  <button
                    className="back-button button custom-decoder-sheet-btn"
                    type="dashed"
                    onClick={() => addNewDateField(statementType)}
                  >
                    Add more
                  </button>
                </div>
                {/* <div className="decoder-input">
                  <div
                    className={`input - value input - date ${ arrayIndex === 3 ? "active-feild" : ""}`}
                      onClick={() => setArrayIndex(3)}
                  >
                      {
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date3
                          ?.text
                      }
                      <img src={images["calendar-ant.svg"]} alt="calender" />
                    </div>
                    <div className="decoder-select-wrap">
                      <Select
                        placeholder="Select months"
                        optionFilterProp="label"
                        disabled={
                          statementType === "BALANCE_SHEET" ||
                          !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                            ?.date3?.text
                        }
                        onChange={(e) => handleDuration(e, "duration2")}
                        value={
                          updatedSheetGuidance?.[sheetName?.[currentIndex]]
                            ?.duration2
                        }
                        className="decoder-select"
                        options={DURATION_MAP}
                        id="5"
                        status={validationErrors.duration2 ? "error" : ""}
                      />

                    </div>
                    <div className="multiple-error">
                      {validationErrors.date3 &&
                        !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                          ?.date3 && (
                          <div className="error-message">
                            {validationErrors.date3}
                          </div>
                        )}
                      {validationErrors.duration2 &&
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date3
                          ?.text && (
                          <div className="error-message">
                            {validationErrors.duration2}
                          </div>
                        )}
                    </div>
                  </div> * /}

                {/* <div className="decoder-input">
                  <div
                    className={`input-value input-date ${arrayIndex === 4 ? "active-feild" : ""}`}
                    onClick={() => setArrayIndex(4)}
                  >
                    {
                      updatedSheetGuidance?.[sheetName?.[currentIndex]]?.date4
                        ?.text
                    }
                    <img src={images["calendar-ant.svg"]} alt="calender" />
                  </div>
                  <div className="decoder-select-wrap">
                    <Select
                      placeholder="Select months"
                      optionFilterProp="label"
                      disabled={
                        statementType === "BALANCE_SHEET" ||
                        !updatedSheetGuidance?.[sheetName?.[currentIndex]]
                          ?.date4?.text
                      }
                      onChange={(e) => handleDuration(e, "duration2")}
                      value={
                        updatedSheetGuidance?.[sheetName?.[currentIndex]]
                          ?.duration2
                      }
                      className="decoder-select"
                      options={DURATION_MAP}
                      id="6"
                      status={validationErrors.duration2 ? "error" : ""}
                    />

                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <ButtonGroupFooter
          buttonGroupObject={buttonGroup}
          className={"fixed-button-group"}
        />
      </div>
    </>
  );
};
