import React, { memo, useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import {
  Formula,
  SpreadsheetComponent,
  getIndexesFromAddress,
  getCell,
  toolbar,
} from "@syncfusion/ej2-react-spreadsheet";
import { Empty, Skeleton } from "antd";
import { ReactTyped } from "react-typed";
import "./sheetComponent.scss";
import { syncfusionSpreedSheetBaseUrl } from "../api/api";
import { focus } from "@syncfusion/ej2-react-spreadsheet";
import API from "../../utils/api/api";
import FallbackPlaceholder from "../../utils/components/fallback";
function SheetComponent({
  getValueOnClick = () => {},
  getCellIndexValue = () => {},
  handleSaveSheetCb = () => {},
  file,
  spreadsheetRef,
  loading = true,
  loadingMessage = "",
  className = "",
  onOpenActionCb = () => {},
  handleHideRowsOnSheetOpen = () => {},
  beforeSaveCb = () => {},
  saveCompleteCb = () => {},
  showExportButton = false,
  activeSheet = () => {},
  showBottomTabs = true,
  sheetIndex = 0,
  enableVirtualization = false,
  loadingsheetStatus = () => {},
  disableMenu = true,
}) {
  // const scrollSettings = {
  //   isFinite: true,
  //   enableVirtualization: enableVirtualization,
  // };
  const disableheader = false;
  const [isSheetOpening, setIsSheetOpening] = useState(false);
  const [openFileResult, setOpenFileResult] = useState(null);
  const [openFailed, setOpenFailed] = useState(false);

  useEffect(() => {
    if (file && file.file) {
      openExcel({
        file: file.file,
        extension: "xlsx",
        password: "",
      });
    }
  }, [file]);
  const convertExcelDate = (serialDate) => {
    const excelEpoch = new Date(1899, 11, 30); // Excel's epoch date is Dec 30, 1899
    return new Date(excelEpoch.getTime() + serialDate * 24 * 60 * 60 * 1000);
  };

  // Format the JavaScript Date into a readable string
  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const onClick = (args) => {
    let valuesArray = [];
    let allValuesArray = [];
    let index = getIndexesFromAddress(args.range);
    spreadsheetRef.current
      ?.getData("A" + (index[0] + 1) + ":" + "Z" + (index[0] + 1))
      .then((data) => {
        const displayTextsArray = [...data.values()].map((entry, i) => {
          const rowIndex = index[0];
          const colIndex = i;

          const cell = getCell(
            rowIndex,
            colIndex,
            spreadsheetRef.current?.getActiveSheet()
          );
          const displayText = spreadsheetRef.current?.getDisplayText(cell);
          return displayText || entry.value;
        });

        const valuesArray = displayTextsArray.filter(
          (value) => value !== undefined
        );
        getValueOnClick(valuesArray);
        getCellIndexValue(index, args, displayTextsArray); // Pass all values (including undefined if needed)
      });
  };

  const onActionComplete = (args) => {
    handleSaveSheetCb(args, getSheetFromJson);
  };

  const onOpenExcelComplete = (args) => {
    onOpenActionCb(args);
  };

  const onDataBoundComplete = (args) => {
    handleHideRowsOnSheetOpen();
  };

  const saveCompleteHandler = (args) => {
    saveCompleteCb(args, spreadsheetRef);
  };

  const getSheetFromJson = async () => {
    const json = await spreadsheetRef.current.saveAsJson();
    return json;
  };
  const fileMenuBeforeOpen = () => {

    spreadsheetRef.current?.enableFileMenuItems(
      ["New", "Open"],
      false,
      disableheader
    );
  };
  const onCreated = async () => {
    if (openFileResult.data.Workbook && openFileResult.data.Workbook.sheets) {
      spreadsheetRef.current.openFromJson({ file: openFileResult.data });
      handleSaveSheetCb({ action: "openSheet" }, getSheetFromJson);
    }
    focus(spreadsheetRef?.current?.element);
    spreadsheetRef.current.activeSheetIndex = 0;
    spreadsheetRef.current.showSheetTabs = showBottomTabs;
    spreadsheetRef.current.allowEditing = disableMenu;
    spreadsheetRef.current?.enableRibbonTabs(
      ["Home", "Insert", "Data", "View"],
      disableheader
    );
    activeSheet();
  };

  const beforeSaveHandler = (eventArgs) => {
    beforeSaveCb(eventArgs, spreadsheetRef);
  };

  const beforeOpenHandler = (eventArgs) => {
    eventArgs.cancel = true;
  };

  const openExcel = async (requestData) => {
    try {
      setIsSheetOpening(true);
      const url = syncfusionSpreedSheetBaseUrl + "open";
      const result = await API({
        method: "POST",
        path: url,
        data: JSON.stringify(requestData),
        disableOverridePrompts: true,
      });
      if (!result) {
        setOpenFailed(true);
      }
      setOpenFileResult(result);
      setIsSheetOpening(false);
      loadingsheetStatus(false);
    } catch (error) {
      setIsSheetOpening(false);
      loadingsheetStatus(true);
      console.error("Error in GET request:", error);
    }
  };

  return (
    <div tabIndex="1" className={`spredSheet-container ${className}`}>
      {openFileResult ? (
        <SpreadsheetComponent
          select={onClick}
          ref={spreadsheetRef}
          actionComplete={onActionComplete}
          saveComplete={saveCompleteHandler}
          beforeSave={beforeSaveHandler}
          fileMenuBeforeOpen={fileMenuBeforeOpen}
          created={onCreated}
          toolbar={[]}
          // height={600}
          //  enablePersistence
          allowOpen={true}
          openComplete={onOpenExcelComplete}
          dataBound={onDataBoundComplete}
          openUrl={syncfusionSpreedSheetBaseUrl + "open"}
          saveUrl={
            showExportButton ? syncfusionSpreedSheetBaseUrl + "save" : ""
          }
          // scrollSettings={scrollSettings}
          allowScrolling={true}
        ></SpreadsheetComponent>
      ) : loading || isSheetOpening ? (
        <div className="loading-sheet">
          <div className="loading-skeleton">
            <Skeleton active />
            <ReactTyped
              strings={[loadingMessage]}
              typeSpeed={25}
              style={{ color: "var(--black-color)" }}
            />
            <Skeleton active />
          </div>
        </div>
      ) : openFailed ? (
        <FallbackPlaceholder />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}

const EmptyView = () => {
  return (
    <div className="loading-sheet">
      <div className="loading-skeleton">
        <Empty />
      </div>
    </div>
  );
};

export default memo(SheetComponent, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
