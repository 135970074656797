import images from "../images/images";
import Aries from "../../locale/en-Us.json";
export const menuItems = [
  // {
  //   name: Aries.HOME_LEFTNAV_HOME,
  //   imageLink: images["home.svg"],
  //   path: "/home",
  //   screen: "home",
  // },
  {
    name: Aries.HOME_LEFTNAV_MICROAPPS,
    imageLink: images["layout-grid.svg"],
    path: "/",
    screen: "microapps",
    status: true,
  },
  {
    name: Aries.HOME_LEFTNAV_MASTER_MANAGER,
    imageLink: images["contact-icon.svg"],
    path: "/practice-manager",
    screen: "practice-manager",
    status: true,
  },
  {
    name: Aries.HOME_LEFTNAV_MASTER_MANAGER_MASTER,
    imageLink: images["data-manager.svg"],
    path: "/master-manager",
    screen: "master-manager",
    status: true,
  },
  // {
  //   name: Aries.HOME_LEFTNAV_AIDA,
  //   imageLink: images["chat.svg"],
  //   path: "/chat",
  //   screen: "chat",
  // },
  {
    name: Aries.HOME_LEFTNAV_CLIENTS,
    imageLink: images["users-round.svg"],
    path: "/clients",
    screen: "clients",
    status: true,
  },
  {
    name: Aries.HOME_LEFTNAV_PROJECTS,
    imageLink: images["file-stack.svg"],
    path: "/engagement-planner",
    screen: "engagement-planner",
    status: true,
  },
  // {
  //   name: Aries.HOME_LEFTNAV_TASKS,
  //   imageLink: images["list-todo.svg"],
  //   path: "/",
  //   screen: "list",
  // },
  // {
  //   name: Aries.HOME_LEFTNAV_CALENDAR,
  //   imageLink: images["calendar.svg"],
  //   path: "/",
  //   screen: "calendar",
  // },
  // {
  //   name: Aries.HOME_LEFTNAV_DOCUMENTS,
  //   imageLink: images["text-file.svg"],
  //   path: "/",
  //   screen: "text",
  // },
  // {
  //   name: Aries.HOME_LEFTNAV_ADMIN,
  //   imageLink: images["gauge-circle.svg"],
  //   path: "/",
  //   screen: "gauge",
  // },
];
