import { Modal } from "antd";
import "./exportModalComponent.scss";
import images from "../images/images";

export const ExportModal = ({
  visible,
  handleDownloadExcel,
  handleExportCch,
  onClose,
  exportButtontext = null,
}) => {
  return (
    <Modal
      className="export-modal"
      open={visible}
      onCancel={onClose}
      centered
      width={"645px"}
      footer={() => (
        <div className="modal-actions">
          <button onClick={handleDownloadExcel} className="button back-button">
            <img src={images["document-download-light.svg"]} alt="" />
            {exportButtontext
              ? "Download as " + exportButtontext
              : "Download as Excel"}
          </button>
          <button onClick={handleExportCch} className="button cch-button">
            <img src={images["cch.svg"]} alt="" />
            Export to CCH
          </button>
        </div>
      )}
    >
      <div className="modal-content">
        <img src={images["outline-export.svg"]} alt="icon" />
        <div className="modal-typography">
          <span className="primary-text">Export File</span>
          <span className="secondary-text">
            {exportButtontext
              ? "Download the file as " + exportButtontext + " or Export to CCH"
              : "Download the file as excel or Export to CCH"}
          </span>
        </div>
      </div>
    </Modal>
  );
};
