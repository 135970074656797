import React, { memo, useState, useRef, useEffect } from "react";
import "../chatBot/style.scss";
import images from "../images/images";
import { chatStart, getChatHistory, sendChatMessage } from "./api";
import { useSelector } from "react-redux";
import Aries from "../../locale/en-Us.json";

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [currentlyTyping, setCurrentlyTyping] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [minimize, setMinmize] = useState(false);

  const [inputText, setInputText] = useState("");
  const profileData = useSelector((state) => state.userData);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const typingSpeed = 35; // milliseconds per character

  // useEffect(() => {
  //   startChat();
  // }, []);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const openChat = () => {
    setIsOpen(true);

    if (minimize === false) {
      setIsTyping(false);
      setLoadingResponse(false);
      setMessages([]);
      startChat();
    }
    setMinmize(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const renderMessageContent = (content) => {
    return { __html: content };
  };
  const startChat = async () => {
    const response = await chatStart();
    if (response) {
      setSessionId(response?.session_id);
      setMessages([...messages, response?.initial_message]);
    }
  };

  const sendChatMessageData = async (list, message) => {
    setIsTyping(true);
    setLoadingResponse(true);
    const data = { content: message, role: "user" };
    const response = await sendChatMessage(sessionId, data);
    setLoadingResponse(false);
    let typedContent = "";
    for (let i = 0; i < response?.choices[0]?.message?.content.length; i++) {
      typedContent += response?.choices[0]?.message?.content[i];
      setCurrentlyTyping(typedContent);
      if (i % 100 === 0) {
        scrollToBottom();
      }
      await new Promise((resolve) => setTimeout(resolve, typingSpeed));
    }
    const newMessage = response?.choices[0]?.message;
    setMessages([...list, newMessage]);
    setCurrentlyTyping("");
    setIsTyping(false);
  };

  const handleSendMessage = () => {
    if (inputText.trim()) {
      const newMessage = {
        content: inputText,
        role: "user",
      };
      setIsTyping(false);
      setMessages([...messages, newMessage]);
      sendChatMessageData([...messages, newMessage], inputText);
      setInputText("");
    }
  };
  const closeAndClear = () => {
    setMessages([]);
    setIsOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };
  return (
    <div className="aida-chat-bot">
      {isOpen ? (
        <div className="chatbot-box">
          <div className="header-wrapper">
            <div className="header-content">
              <div className="bot-profile">
                <img
                  src={images["aistra-aida.svg"]}
                  loading="lazy"
                  alt="aida"
                />
              </div>
              <div className="bot-title-wrapper">
                <div className="title">{Aries.BOT_NAME}</div>
                <div className="subtitle">
                  <img src={images["online.svg"]} loading="lazy" alt="online" />
                </div>
              </div>
            </div>
            <div className="header-action">
              <img
                onClick={() => {
                  setIsOpen(false);
                  setMinmize(true);
                }}
                src={images["minus.svg"]}
                loading="lazy"
                alt="minimize"
              />
              <img
                onClick={closeAndClear}
                src={images["close-chat.svg"]}
                loading="lazy"
                alt="close"
              />
            </div>
          </div>
          <div className="body-wrapper">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.role === "user" ? "text-right" : "text-left"
                }`}
              >
                <div className="user-name">
                  {message.role === "assistant" ? (
                    <img
                      src={images["aistra-aida.svg"]}
                      loading="lazy"
                      alt="aida"
                      className="aida-icon-bot"
                    />
                  ) : (
                    <img
                      className="aida-icon-bot"
                      src={
                        profileData && profileData?.base64ImageString
                          ? `data:image/png;base64,${profileData?.base64ImageString}`
                          : images["user_image.svg"]
                      }
                      loading="lazy"
                      alt="onboarding logo"
                    />
                  )}
                  <div className="name">
                    {message.role === "assistant" ? Aries.BOT_NAME : "You"}
                  </div>
                </div>
                <div
                  className={`text-wrapper ${
                    message.role === "assistant" ? "aida-ui" : "user-ui"
                  }`}
                >
                  <div
                    className="text"
                    dangerouslySetInnerHTML={renderMessageContent(
                      message.content
                    )}
                  />
                </div>
              </div>
            ))}
            {loadingResponse && (
              <div className="message text-left">
                <div className="user-name">
                  <img
                    src={images["aistra-aida.svg"]}
                    loading="lazy"
                    alt="aida"
                    className="aida-icon-bot"
                  />
                  <div className="name">{Aries.BOT_NAME}</div>
                </div>
                <div className="text-wrapper aida-ui">
                  <div className="loading-chat"></div>
                </div>
              </div>
            )}

            {isTyping && !loadingResponse && (
              <div className="message text-left">
                <div className="user-name">
                  <img
                    src={images["aistra-aida.svg"]}
                    loading="lazy"
                    alt="aida"
                    className="aida-icon-bot"
                  />
                  <div className="name">{Aries.BOT_NAME}</div>
                </div>
                <div className="text-wrapper aida-ui">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={renderMessageContent(
                      currentlyTyping
                    )}
                  />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="footer-wrapper">
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={
                isTyping
                  ? `${Aries.BOT_NAME} generating message...`
                  : "Type your message here..."
              }
              className="input-wrapper"
              disabled={isTyping}
              ref={inputRef}
            />
            <img
              onClick={handleSendMessage}
              className="send-icon"
              src={images["send.svg"]}
              loading="lazy"
              alt="send"
            />
          </div>
        </div>
      ) : (
        <img
          onClick={() => openChat()}
          className="aida-icon"
          src={images["aida-chat.svg"]}
          loading="lazy"
          alt="Open chat"
        />
      )}
    </div>
  );
};

export default memo(ChatBot);
