import React, { memo, useEffect, useState } from "react";
import { menuItems } from "./leftMenu";
import { useNavigate, useLocation } from "react-router-dom";

import "./leftMenu.scss";
import images from "../images/images";
import ComingSoon from "../model/comingSoon";

const LeftMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModel, setShowModel] = useState(false);
  const [pressedKeys, setPressedKeys] = useState(new Set());
  const shortcutHandlers = {
    "a+h": () => handleClickMenuItem(""),
    "m+a": () => handleClickMenuItem(""),
    "a+i": () => handleClickMenuItem(""),
    "a+c": () => handleClickMenuItem(""),
    "a+p": () => handleClickMenuItem(""),
    "a+t": () => handleClickMenuItem(""),
    "a+m": () => handleClickMenuItem(""),
    "a+d": () => handleClickMenuItem(""),
    "p+m": () => handleClickMenuItem(""),
    "a+r": () => handleClickMenuItem(""),
    "a+s": () => handleClickMenuItem(""),
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const keyCombination =
        Array.from(pressedKeys).sort().join("+") +
        `+${event?.key?.toLowerCase()}`;
      if (shortcutHandlers[keyCombination]) {
        shortcutHandlers[keyCombination]();
      }
      if (!pressedKeys.has(event?.key?.toLowerCase())) {
        setPressedKeys(
          (prevKeys) => new Set([...prevKeys, event?.key?.toLowerCase()])
        );
      }
    };

    const handleKeyUp = (event) => {
      setPressedKeys(
        (prevKeys) =>
          new Set(
            [...prevKeys].filter((key) => key !== event?.key?.toLowerCase())
          )
      );
    };

    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [pressedKeys]);

  function handleClickMenuItem(path) {}

  function navigateToPage(path, status) {
    if (!status) {
      setShowModel(true);
    } else {
      navigate(path);
    }
  }
  const handleClose = () => {
    setShowModel(false);
  };

  function getIsCurrentPath(screen) {
    const { pathname } = location;
    if (pathname === "/" || pathname === "/onboarding") {
      return screen === "microapps";
    } else {
      return pathname.includes(screen);
    }
  }

  return (
    <div className="left-menu">
      <div className="header-item">
        <a href="/">
          <img
            className="header-logo"
            src={images["aistra-logo.svg"]}
            loading="lazy"
            alt="onboarding logo"
          />
          <span className="description">
            <img src={images["AistraLabsLogo.svg"]} alt="Aistra Labs" />
          </span>
        </a>
      </div>
      <div className="menu-items">
        {menuItems.map((item) => {
          return (
            <div
              className={
                getIsCurrentPath(item.screen)
                  ? "selected menu-item"
                  : "menu-item"
              }
              onClick={() => {
                navigateToPage(item.path, item.status);
                localStorage.setItem("navigationName", item.path);
              }}
              key={item.name}
            >
              <img className="icon" src={item.imageLink} alt={item.name} />
              <span className="description">{item.name}</span>
            </div>
          );
        })}
      </div>
      <div className="open-drawer-button">
        <img src={images["right-arrow-2.svg"]} alt="arrow" />
      </div>
      <ComingSoon showModel={showModel} handleClose={handleClose} />
    </div>
  );
};

export default LeftMenu;
