import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from "antd";
import { commanRegex, tableInputTypes } from "../../utils/constants";
import dayjs from "dayjs";

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  isRequiredField = false,
  inputType = "",
  record,
  handleSave,
  defaultEditing = false,
  options = [],
  width,
  regCheck = commanRegex,
  valueKey = "",
  labelKey = "",
  dateFormat = "YYYY-MM-DD",
  timeFormat = "hh:mm:ss A",
  minNumValue = 0,
  ...restProps
}) => {
  const [editing, setEditing] = useState(defaultEditing);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const isComplexObject = (value) => {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  };

  const toggleEdit = () => {
    setEditing(!editing);
    try {
      let value = record[dataIndex];
      if (record[dataIndex]) {
        value = isComplexObject(record[dataIndex])
          ? record[dataIndex][valueKey]
          : inputType === tableInputTypes.DATEPICKER
            ? dayjs(record[dataIndex], dateFormat)
            : inputType === tableInputTypes.TIME
              ? dayjs(record[dataIndex], timeFormat)
              : record[dataIndex];
      }
      form.setFieldsValue({
        [dataIndex]: value,
      });
    } catch (e) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
      console.log(e);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      let newValue = values[dataIndex];
      if (newValue && inputType === tableInputTypes.DATEPICKER) {
        newValue = dayjs(newValue).format(dateFormat);
      }
      if (newValue && inputType === tableInputTypes.TIME) {
        newValue = dayjs(newValue).format(timeFormat);
      }
      if (
        inputType === tableInputTypes.SELECT &&
        isComplexObject(record[dataIndex])
      ) {
        const selectedOption = options.find(
          (option) => option.value === newValue
        );
        newValue = {
          [valueKey]: selectedOption?.value,
          [labelKey]: selectedOption?.label,
        };
      }

      const newRecord = {
        ...record,
        [dataIndex]: newValue,
      };

      handleSave(newRecord);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  const inputNode = getEditableCellNode(
    inputType,
    "editable-textarea",
    inputRef,
    save,
    options,
    form,
    dataIndex,
    minNumValue
  );
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          width: width,
        }}
        name={dataIndex}
        rules={[
          {
            required: isRequiredField,
            message: `${title} is required.`,
          },
          {
            validator: (_, value) => {
              if (value) {
                if (regCheck.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(" *Invalid input"));
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const getEditableCellNode = (
  inputType,
  className,
  ref,
  save,
  options,
  form,
  dataIndex,
  minNumValue
) => {
  switch (inputType) {
    case tableInputTypes.NUMBER:
      return (
        <InputNumber
          className={className}
          ref={ref}
          onPressEnter={save}
          onBlur={save}
          type="number"
          rootClassName={className}
          min={minNumValue}
        />
      );
    case tableInputTypes.TIME:
      return (
        <TimePicker
          onChange={save}
          onOk={save}
          allowClear={false}
          ref={ref}
          use12Hours
        />
      );
    case tableInputTypes.SELECT:
      return (
        <Select
          ref={ref}
          onChange={save}
          options={options}
          onBlur={save}
          className="global-select-component-secondary"
        />
      );
    case tableInputTypes.AUTOCOMPLETE:
      return (
        <AutoComplete
          ref={ref}
          options={options}
          placeholder=""
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onBlur={save}
          onSelect={save}
          className="table-autocomplete global-select-component-secondary"
        >
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                save();
              }
            }}
            className="autocomplete-input"
          />
        </AutoComplete>
      );
    case tableInputTypes.DATE:
      return (
        <Input
          className={className}
          ref={ref}
          onPressEnter={save}
          onBlur={save}
          type="date"
        />
      );
    case tableInputTypes.DATEPICKER:
      return <DatePicker ref={ref} className={className} onChange={save} />;
    default:
      return (
        <Input.TextArea
          className={className}
          ref={ref}
          onPressEnter={save}
          onBlur={save}
          autoSize
        />
      );
  }
};
