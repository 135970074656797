import { Radio } from "antd";
import { PROJECT_ID } from "../pages/decoder-v2/constant";

export const radioSelectionListColumn = (selectRow, selectedId) => {

  const columns = [
    { title: "ENGAGEMENT NAME", dataIndex: "name", key: PROJECT_ID },
    {
      title: "SELECT",
      render: (record) => (
        <>
          <Radio
            value={JSON.stringify(record)}
            onChange={selectRow}
            checked={record?.[PROJECT_ID] === selectedId}
          />
        </>
      ),
      key: "name",
      width: "10%",
    },
  ];


  return columns;
};
