import React from "react";
import "../utils.scss";
import { FileExclamationOutlined } from "@ant-design/icons";
//temporary for now
const FallbackPlaceholder = () => {
  return (
    <div className="fallback-placeholder">
      <FileExclamationOutlined className="insight-icon" />
      <p className="message">Oops something went wrong. Please try again</p>
    </div>
  );
};

export default FallbackPlaceholder;
