import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as ExcelJS from "exceljs";
import * as xlsx from "xlsx";
import images from "../components/images/images";
import "./utils.scss";

import { persistStore } from "redux-persist";
import apiRequest, {
  syncfusionSpreedSheetBaseUrl,
} from "../components/api/api";
import { store } from "../redux/store";
import { logout } from "../redux/reducers";
import { commanRegex, notificationTypes, PROJECT_ID } from "./constants";
import { Radio } from "antd";
import { TaskStatusScreenSkeleton } from "../components/taskStatusComponent";

dayjs.extend(customParseFormat);

export const downloadDocumentFile = async ({ response, cikNumber }) => {
  const binaryData = await response.arrayBuffer();
  const blob = new Blob([response], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = cikNumber + ".docx";
  link.click();

  // Clean up the temporary URL
  URL.revokeObjectURL(url);
};

export const downloadBase64File = (file, cikNumber = null) => {
  try {
    let fileName = file?.name;
    let fileExtension = file.extension || ".pdf";

    const byteCharacters = atob(file.file); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = (fileName || "downloadedFile") + fileExtension; // Specify the desired file name and extension
    link.click();

    URL.revokeObjectURL(url);
    return true;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const convertBinaryFiletoExcel = async (
  defaultFile,
  fileName = "audit_document.xlsx"
) => {
  try {
    const byteCharacters = atob(defaultFile.file); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const url = URL.createObjectURL(blob);
    const response = await fetch(url);
    const fileBlob = await response.blob();
    const file = new File([fileBlob], fileName);
    return file;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
export const convertBinaryFiletoDocx = async (
  defaultFile,
  fileName = "document.docx"
) => {
  try {
    const byteCharacters = atob(defaultFile.file); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    const url = URL.createObjectURL(blob);
    const response = await fetch(url);
    const fileBlob = await response.blob();
    const file = new File([fileBlob], fileName, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    return file;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const tableLoading = ({ loading, text }) => {
  return {
    spinning: loading,
    indicator: (
      <div className="spinner-div">
        <img src={images["spinner.svg"]} alt="spinner" />
        <span className="loader-text">{text}</span>
      </div>
    ),
  };
};

export const trackerTableLoading = ({ loading }) => {
  return {
    spinning: loading,
    indicator: <TaskStatusScreenSkeleton />,
  };
};

export const convertExcelToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });

      const fileReader = new FileReader();
      fileReader.onload = () => {
        const base64String = fileReader.result.split(",")[1];
        resolve(base64String);
      };
      fileReader.readAsDataURL(blob);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const convertBlobToBase64String = (blobData) => {
  if (!blobData) {
    return "";
  }
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = function () {
      let base64String = reader.result.replace(
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        ""
      );
      resolve(base64String);
    };
    reader.onerror = function () {
      reject(new Error("Error reading blob data"));
    };
  });
};

export function convertToUSDString(value) {
  if (!value) {
    return "";
  }
  try {
    let usCurrency = value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return usCurrency;
  } catch (e) {
    console.log("Error in converting number to USD value", e);
    return value;
  }
}

export function convertUSDToNumber(usdString) {
  if (!usdString) {
    return usdString;
  }
  let cleanedString = usdString.replace(/[$,]/g, "");
  let number = parseFloat(cleanedString);
  return number;
}

export function extractRowsFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
        const sheet = workbook.Sheets[sheetName];
        const rows = xlsx.utils.sheet_to_json(sheet, { header: 1 });
        resolve(rows);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(blob);
  });
}

export const handleFileChange = (fileBlob) => {
  const reader = new FileReader();

  reader.onload = async (e) => {
    const buffer = e.target.result;
    const arrayBuffer = new Uint8Array(buffer);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheet = workbook.getWorksheet(1);
    const data = [];

    sheet.eachRow((row) => {
      const rowData = [];
      row.eachCell((cell) => {
        rowData.push(cell.value);
      });
      data.push(rowData);
    });
  };

  reader.readAsArrayBuffer(fileBlob);
};

export const exportDocumentFile = async (file, name) => {
  if (file) {
    try {
      const blob = await file.saveAsBlob("Docx");
      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return {
          status: notificationTypes.SUCCESS,
          title: "Success",
          description: "The file has been downloaded.",
        };
      } else {
        return {
          status: notificationTypes.ERROR,
          title: "Error",
          description: "Failed to create blob for document content.",
        };
      }
    } catch (error) {
      console.error("Error while saving document as blob:", error);
      return {
        status: notificationTypes.ERROR,
        title: "Error",
        description: "Failed to create blob for document content.",
      };
    }
  }
};

export const exportSheetWithSyncfusion = async (spreadsheet, name) => {
  await spreadsheet.save({
    url: syncfusionSpreedSheetBaseUrl + "save",
    fileName: name,
    saveType: "Xlsx",
  });
};

export const handleFetchLLMList = () => {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const url = `llm`;
    const result = apiRequest(url, "GET", storedUserData);
    return result;
  } catch (error) {
    console.error("Error in GET request:", error);
    return [];
  }
};

export const replaceRequiredFields = (text, clientName = "") => {
  if (!text || !clientName) {
    return text;
  }
  try {
    return text.replace(/{clientName}/g, clientName);
  } catch (error) {
    console.error("Error in GET request:", error);
    return text;
  }
};

export const convertDataIntoSelectFormat = (
  data,
  formatFunction = (item) => item
) => {
  try {
    return (
      data?.map((item) => {
        return { value: item, label: formatFunction(item) };
      }) || []
    );
  } catch (e) {
    console.log(e);
    return [];
  }
};

export function compareTimes(time1, time2) {
  try {
    // Parse the times using dayjs
    const parsedTime1 = dayjs(time1, [
      "hh:mm:ss A",
      "hh:mm A",
      "HH:mm",
      "HH:MM:SS",
    ]);
    const parsedTime2 = dayjs(time2, [
      "hh:mm:ss A",
      "hh:mm A",
      "HH:mm",
      "HH:MM:SS",
    ]);

    // Compare the time values
    if (parsedTime1.isAfter(parsedTime2)) {
      return true; // time1 is later than time2
    }
    return false; // time1 and time2 are the same
  } catch (error) {
    console.error("Error comparing times:", error);
    return false; // Return false in case of any error
  }
}

export function compareDates(date1, date2) {
  try {
    return !dayjs(date1).isAfter(dayjs(date2));
  } catch (error) {
    console.error("Error comparing times:", error);
    return false; // Return false in case of any error
  }
}

export function isPastDateTime(dateStr, timeStr, format = "hh:mm:ss A") {
  try {
    const inputDate = dayjs(dateStr);
    let inputDateTime;

    if (timeStr) {
      const parsedTime = dayjs(timeStr, format);
      inputDateTime = inputDate
        .hour(parsedTime.hour())
        .minute(parsedTime.minute())
        .second(parsedTime.second());
    } else {
      inputDateTime = inputDate;
    }

    const currentDateTime = dayjs();
    if (inputDateTime.isAfter(currentDateTime)) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error in isPastDateTime:", error);
    return false;
  }
}

export const customFormatMonthDay = (value) => {
  return `${dayjs(value).format("MMMM")} ${dayjs(value).format("D")}' ${dayjs(value).format("YY")}`;
};

export function validateInputCompanyName(input) {
  const regex = commanRegex;
  return regex.test(input);
}

export const convertDataIntoFiltersFormatById = (
  data,
  keyId,
  keyName,
  keyNameMetaData = ""
) => {
  try {
    return (
      data?.map((item) => {
        return {
          text: item?.[keyName],
          value: item?.[keyId],
          label: item?.[keyName],
          metaData: item?.[keyNameMetaData] || null,
        };
      }) || []
    );
  } catch (e) {
    console.log(e);
    return [];
  }
};

export function arraysAreEqual(arr1, arr2) {
  const sortedArr1 = arr1?.slice().sort();
  const sortedArr2 = arr2?.slice().sort();
  if (sortedArr1?.length !== sortedArr2?.length) {
    return false;
  }
  for (let i = 0; i < sortedArr1?.length; i++) {
    if (sortedArr1?.[i] !== sortedArr2?.[i]) {
      return false;
    }
  }
  return true;
}

export const base64FileToBlob = (file, cikNumber = null) => {
  try {
    let fileName = file?.name;
    let fileExtension = file.extension || ".pdf";

    const byteCharacters = atob(file.file); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const url = URL.createObjectURL(blob);

    // const link = document.createElement("a");
    // link.href = url;
    // link.download = (fileName || "downloadedFile") + fileExtension; // Specify the desired file name and extension
    // link.click();

    // URL.revokeObjectURL(url);
    return url;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const clearLocalSaveData = (dispatch) => {
  localStorage.removeItem("userData");
  persistStore(store).purge();
  dispatch(logout());
};

export const columnSelectEngagement = (selectRow, selectedId) => {
  const columns = [
    { title: "ENGAGEMENT NAME", dataIndex: "name", key: PROJECT_ID },
    {
      title: "SELECT",
      render: (record) => (
        <Radio
          value={JSON.stringify(record)}
          onChange={selectRow}
          checked={record[PROJECT_ID] === selectedId}
        />
      ),
      key: "name",
      width: "10%",
    },
  ];

  return columns;
};

export const normalizeJsonData = (jsonData) => {
  if (!jsonData || !jsonData.length) {
    return jsonData;
  }
  let currentStep = jsonData[0].step;
  return jsonData.map((item) => {
    const normalizedItem = { ...item, step: currentStep };
    currentStep++;
    return normalizedItem;
  });
};
