import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import BackNextActionButtonComponent from "../backNextActionButtonComponent";
import images from "../images/images";
import "./infoMessageComponent.scss";
import { customFormatMonthDay } from "../../utils/utils";
import ButtonGroupFooter from "../buttonGroupFooter";
import Aries from "../../locale/en-Us.json"
const InfoMessageComponent = ({
  label,
  nextStep,
  backStep,
  page,
  nextbtnName,
  showNext,
  renderDate,
  keyIndex,
  date = null,
  periodEndDate = null,
  onChangeDate = () => { },
  onSelectPeriodEndDate = () => { },
  periodEndOptions = [],
}) => {
  const navigate = useNavigate();
  const data2 = ["Income Statement", "Balance sheet "];
  const disabledDate = (current) => {
    // Disable all years except the current year
    return (
      current &&
      !(
        dayjs(current).isSame(dayjs(), "year") ||
        dayjs(current).isSame(dayjs().add(1, "year"), "year")
      )
    );
  };

  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: page ? () => navigate(page) : backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        rightImageLink: images["arrow-right.svg"],
        className: "primary-button ",
        fn: nextStep,
        disabled: renderDate && (!date || !periodEndDate)
      },
    ],
  };

  return (
    <div className="welcome-container" key={keyIndex}>
      <div className="wrapper-label">
        <img
          className="user-icon"
          src={images["aistra-vaa-avatar-1.svg"]}
          loading="lazy"
          alt="User icon"
        />
        <div
          className="label"
          dangerouslySetInnerHTML={{ __html: label }}
        ></div>
      </div>
      {renderDate && (
        <div className="date-pickers-year-end">
          <div className="financial-year">
            <label>Fiscal year end</label>
            <DatePicker
              onChange={(date, dateString) => onChangeDate(date, dateString)}
              defaultValue={date ? dayjs(date, "YYYY-MM-DD") : null}
              format={customFormatMonthDay}
              disabledDate={disabledDate}
              suffixIcon={<img src={images["calendar-days.svg"]} alt="cal" />}
              allowClear={false}
            />
          </div>
          <div className="financial-year">
            <label>Period end date</label>
            <Select
              options={periodEndOptions}
              onChange={onSelectPeriodEndDate}
              defaultValue={null}
              rootClassName="custom-select"
              placeholder="Select date"
              suffixIcon={<img src={images["chevron-down.svg"]} alt="open" />}
              value={periodEndDate}
            />
          </div>
        </div>
      )}

      {/* <BackNextActionButtonComponent
        showBack={true}
        disableBack={false}
        disableNext={renderDate && (!date || !periodEndDate)}
        showNext={showNext === "hide" ? false : true}
        backActionStep={page ? () => navigate(page) : backStep}
        nextActionStep={nextStep}
        nextbtnName={nextbtnName}
        backIcon={<img src={images["arrow-left.svg"]} alt="left" />}
        nextIcon={<img src={images["arrow-right.svg"]} alt="right" />}
      /> */}
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
    </div>
  );
};
export default InfoMessageComponent;
