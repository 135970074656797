import apiRequest from "../../components/api/api";
import API from "../../utils/api/api";

export const logOut = async () => {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const url = `auth/logout`;
    const result = apiRequest(url, "POST", storedUserData);
    return result;
  } catch (error) {
    console.error("Error in POST request:", error);
  }
};

export const saveActivityDataApi = async ({
  payload,
  dispatch,
  showErrorModal,
}) => {
  try {
    const url = "v1/time-tracker/set";
    const result = await API({
      path: url,
      method: "POST",
      data: payload,
      dispatch,
      showErrorModal,
    });
    return result.data;
  } catch (error) {
    console.error("Error in POST request:", error);
  }
};

export const getLastActivityDataApi = async ({ dispatch }) => {
  try {
    const url = "v1/time-tracker/previous-sessions";
    const result = await API({ path: url, method: "GET", dispatch });
    return result.data;
  } catch (error) {
    console.error("Error in POST request:", error);
  }
};
