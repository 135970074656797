// NotificationComponent.js
import React from "react";
import { Button, notification, Space } from "antd";

const NotificationComponent = React.forwardRef((props, ref) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (status, title, description) => {
    api[status]({
      message: title,
      description: description,
      placement: "topRight",
      duration: 3,
    });
  };

  // Expose the function via ref
  React.useImperativeHandle(ref, () => ({
    openNotificationWithIcon,
  }));

  return (
    <>
      <Space>
        {/* This button is not necessary anymore */}
        {/* <Button onClick={openNotificationWithIcon}>Open notification</Button> */}
      </Space>
      {contextHolder}
    </>
  );
});

export default NotificationComponent;
