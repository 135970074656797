import { Collapse, Drawer, Input } from "antd";
import React from "react";
import images from "../images/images";
import "./tablecomponent.scss";

export const FilterMenu = ({
  close,
  drawerPanels,
  visible,
  handleApplyFilters,
  handleResetFilters,
  handleSearchFilters,
}) => {
  return (
    <Drawer
      title={
        <div className="header">
          <div className="text-wrapper">Filters</div>
          <div onClick={handleResetFilters} className="text-wrapper-reset">
            Reset All
          </div>
        </div>
      }
      closeIcon={null}
      placement="right"
      onClose={close}
      open={visible}
      rootClassName="filter-drawer"
      footer={
        <div className="footer">
          <button
            className="secondary-buttons button full-width"
            onClick={close}
          >
            Cancel
          </button>
          <button
            className="primary-button button full-width"
            onClick={() => handleApplyFilters()}
          >
            Apply Filters
          </button>
        </div>
      }
    >
      <div className="filter-drawer-container">
        <Input
          className="search-wrapper focus-input"
          placeholder="Search for filters"
          prefix={<img src={images["search.svg"]} alt="search" />}
          onChange={(e) => handleSearchFilters(e.target.value)}
        />
        <Collapse
          expandIconPosition="end"
          rootClassName="filter-collapse"
          items={drawerPanels}
        />
      </div>
    </Drawer>
  );
};
