import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkUserLogin, getEngagementListApi } from "./api/commonApi";
import { clearLocalSaveData } from "./utils";
import { useDispatch } from "react-redux";

export const useTokenValidation = () => {
  const [isTokenValid, setIsTokenValid] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateToken = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userData"))?.token; // Adjust based on how you store the token
      if (!token) {
        setIsTokenValid(false);
        navigate("/login");
        clearLocalSaveData(dispatch);
        return;
      }

      const isValid = await checkUserLogin();
      setIsTokenValid(isValid?.token);

      if (!isValid) {
        navigate("/login");
        clearLocalSaveData(dispatch);
      }
    } catch (error) {
      console.error("Token validation error:", error);
      setIsTokenValid(false);
      navigate("/login");
      clearLocalSaveData(dispatch);
    }
  };

  return { isTokenValid, validateToken };
};

export const useGetEngagementList = () => {
  const dispatch = useDispatch();
  const [engagementList, setEngagementList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEngagementList = async (stage, substage) => {
    try {
      setLoading(true);
      setEngagementList([]);
      const response = await getEngagementListApi(dispatch, stage, substage);
      setEngagementList(response?.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in fetching engagement list:", error);
    }
  };

  return { getEngagementList, engagementList, loading };
};
