import React from "react";
import images from "../components/images/images";
import "./utils.scss";

export const CloseIcon = () => {
  return (
    <div className="global-close-icon">
      <img src={images["close.svg"]} alt="Close Icon" />
    </div>
  );
};
