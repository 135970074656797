import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Form, Input, Popover, Table, TreeSelect, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { decoderRegex } from "../../utils/constants";
import { convertToUSDString } from "../../utils/utils";
import images from "../images/images";
import "./commanDecoderSheetComponent.scss";
const { Title } = Typography;

const EditableContext = React.createContext(null);
const regCheck = decoderRegex;
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr style={{ background: "red !important" }} {...props}></tr>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  dataSource,
  columnIndex,
  rowIndex,
  columns,
  decoderSheetname,
  optionMap,
  editAllowed,
  ...restProps

}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing, inputRef]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default tab behavior
      setEditing(true); // Enable editing when tab is pressed
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      // Handle up and down arrow keys
      // Your existing logic for handling up and down arrow keys
    } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      // console.log(columnIndex, "columnIndex");
      // Handle left and right arrow keys
      e.preventDefault(); // Prevent default scroll behavior
      const cellIndex =
        e.key === "ArrowLeft" ? columnIndex - 1 : columnIndex + 1;
      // console.log(columnIndex, cellIndex, "columnIndex");
      // Ensure the next index is within bounds
      if (cellIndex >= 0 && cellIndex < columns.length) {
        // Find the next editable cell and focus on it
        const nextCell = document.querySelector(
          `td[data-column-index="${cellIndex}"][data-row-key="${record.id}"]`
        );
        if (nextCell) {
          nextCell.focus();
        }
      }
    }
  };

  const toggleEdit = () => {
    if (editAllowed) {
      if (record && record.children === undefined) {
        setEditing(!editing);
        if (dataIndex.startsWith("date")) {
          form.setFieldsValue({
            [dataIndex]:
              record.values[parseInt(dataIndex.replace("date", "")) - 1],
          });
        } else {
          form.setFieldsValue({
            [dataIndex]: record[dataIndex],
          });
        }
      }
    };
  }

  const calculateSum = (row, dataIndex) => {
    let sum = 0;
    if (row.children && Array.isArray(row.children)) {
      row.children.forEach((child) => {
        if (child.children && Array.isArray(child.children)) {
          sum += calculateSum(child, dataIndex);
        } else {
          const index = parseInt(dataIndex.replace("date", ""));
          const childValue = parseInt(child.values[index - 1]);
          if (!isNaN(childValue)) {
            sum += childValue;
          }
        }
      });
    }

    return sum;
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (dataIndex.startsWith("date")) {
        const valueIndex = parseInt(dataIndex.replace("date", "")) - 1;
        record.values[valueIndex] = values[dataIndex];
      }
      // if (valid) {
      handleSave({
        record,
        ...values,
      });

      // setEditing(false);
      toggleEdit();
      // }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  function findMappedKeyByID(id, node) {
    if (!node) return null;

    if (node.id === id) {
      return node.mappedKey;
    }

    if (node.children) {
      for (let child of node.children) {
        let result = findMappedKeyByID(id, child);
        if (result) return result;
      }
    }

    return null;
  }

  function searchThroughJsonData(id, jsonData) {
    for (let entry of jsonData) {
      let result = findMappedKeyByID(id, entry);
      if (result) return result;
    }
    return null;
  }

  function findDataOption(id, jsonData, optionMap) {
    let mappedKey = searchThroughJsonData(id, jsonData);
    while (!mappedKey && id.includes(".")) {
      id = id.substring(0, id.lastIndexOf("."));
      mappedKey = searchThroughJsonData(id, jsonData);
    }
    if (optionMap) {
      const mappedKeysSet = new Set(Object.keys(optionMap));
      if (mappedKey && optionMap && mappedKeysSet.has(mappedKey)) {
        return optionMap[mappedKey];
      } else {
        if (id.includes(".")) {
          let newSearchId = id.substring(0, id.lastIndexOf("."));
          return findDataOption(newSearchId, jsonData, optionMap);
        } else {
          return optionMap[decoderSheetname];
        }
      }
    }
  }

  let childNode = children;
  if (editable) {
    childNode =
      (editing && dataIndex !== "reportKey") ||
        (editing &&
          dataIndex !== "reportKey" &&
          record?.children?.length === undefined) ? (
        <Form.Item
          style={{
            margin: "0px ",
            width: "100%",
            borderLeft: ".1px solid #ABABAA",
          }}
          name={dataIndex}
          rules={[
            {
              validator: (_, value) => {
                if (!value || regCheck.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(" *Invalid input"));
              },
            },
          ]}
        >
          {dataIndex === "mappedKey" && optionMap ? (
            <TreeSelect
              ref={inputRef}
              // onPressEnter={save}
              onChange={(newValue, selectedNode) => {
                record.mappedKey = newValue;
                save();
              }}
              onInputKeyDown={save}
              // onMouseLeave={save}
              className="tree-checkbox"
              tabIndex={0}
              style={{
                // width: "89%",
                height: "53px",
                marginLeft: "10px",
                float: "left",
              }}
              value={record.mappedKey}
              treeData={[
                { title: record.reportKey, value: record.reportKey },
                {
                  title: "-----------------------------",
                  value: "",
                  disabled: true,
                  style: { color: "gray" },
                },

                ...findDataOption(record.id, dataSource, optionMap).map(
                  (option) => ({
                    title: option,
                    value: option,
                  })
                ), // Adding the new option here
                ,
              ]}
              dropdownRender={(menu) => (
                <div className="input-drop-wrapper">
                  <label style={{ marginLeft: "10px" }}>
                    <b>Enter standard mapping or select.</b>
                  </label>
                  <div className="input-wrapper">
                    <Input
                      className="input"
                      ref={inputRef}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        record.mappedKey = newValue;
                      }}
                    />
                    <div onClick={save}>
                      {" "}
                      <img
                        className="plus-icon"
                        src={images["plus.svg"]}
                        loading="lazy"
                        alt="plus icon"
                      />
                    </div>
                  </div>
                  <hr></hr>
                  {menu}
                </div>
              )}
            />
          ) : (
            <Input
              className="input"
              style={{ height: "53px", margin: "5px" }}
              ref={inputRef}
              onPressEnter={save}
              // onMouseLeave={save}
              onBlur={save}
              defaultValue={children[1]}
              value={children[1]}
            />
          )}
        </Form.Item>
      ) : (
        <div
          tabIndex={
            record?.children?.length === undefined && dataIndex !== "reportKey"
              ? 0
              : -1
          }
          className={`ant-table-cell-custom${record && record.children === undefined && dataIndex === "mappedKey" ? "2" : ""}${!record || !record.hasOwnProperty("children") ? " ant-table-cell-no-children" : ""}`}
          style={{
            borderLeft:
              dataIndex === "mappedKey" && children[1] === undefined
                ? "none"
                : ".5px solid #ABABAA !important",
            pointerEvents:
              dataIndex === "reportKey" ||
                (record &&
                  record.children !== undefined &&
                  dataIndex !== "mappedKey")
                ? "none"
                : "",

            fontWeight: record && record.children !== undefined ? 600 : 400,
            marginLeft:
              record &&
                record.children === undefined &&
                dataIndex === "mappedKey"
                ? "-54px !important"
                : "",
          }}
          onClick={toggleEdit}
        >
          {editing && dataIndex.startsWith("date") ? (
            <span className="rightalign-text">
              {record.values[parseInt(dataIndex.replace("date", "")) - 1]}
            </span>
          ) : (
            <>
              {dataIndex.startsWith("date") ? (
                record.children ? (
                  <span className="rightalign-text">
                    {convertToUSDString(calculateSum(record, dataIndex))}
                  </span>
                ) : (
                  <span className="rightalign-text">
                    {children[1] === "" ? "-" : children}
                  </span>
                )
              ) : (
                <> {children || "-"}</>
              )}
            </>
          )}
        </div>
      );
  } else {
    childNode = (
      <div className="comments" tabIndex={-1}>
        {children && children[1] && (
          <Popover
            placement="topLeft"
            title={"Comment"}
            content={
              <Title
                style={{
                  fontSize: "12px",
                  maxWidth: "500px",
                  maxHeight: "500px",
                  overflow: "scroll",
                  fontWeight: "400",
                }}
              >
                {children && children[1]}
              </Title>
            }
          >
            <img
              className="comment-icon"
              src={images["comment-icon.svg"]}
              loading="lazy"
              alt="comment icon"
            />
          </Popover>
        )}
      </div>
    );
  }
  return (
    <td style={{ padding: "5px" }} onKeyDown={handleKeyDown} {...restProps}>
      {childNode}
    </td>
  );
};

const CommanDecoderSheetComponent = ({
  sheetTableData,
  getUpdatedSheetData,
  decoderSheetname,
  optionMap,
  editAllowed
}) => {
  const [dataSource, setDataSource] = useState(
    sheetTableData?.table?.dataSource
  );
  const [highlightId, setHighlightId] = useState();

  useEffect(() => {
    setDataSource(sheetTableData?.table?.dataSource);
  }, [sheetTableData]);

  useEffect(() => {
    const element = document.querySelector(
      `[data-row-key="${CSS.escape(highlightId)}"]`
    );
    if (element) {
      element.classList.add("highlight");
    }
  }, [highlightId]);

  const handleDrop = (e, targetId, recordTarget) => {
    e.preventDefault();
    const draggedItem = JSON.parse(e.dataTransfer.getData("draggedItem"));
    if (!recordTarget.children) return;
    if (!draggedItem || targetId === draggedItem.id) return;
    if (targetId?.includes(draggedItem?.id)) return;
    const newDataSource = [...dataSource];

    const removeDraggedItem = (data) => {
      return data.filter((item) => {
        if (item.children) {
          item.children = removeDraggedItem(item.children);
          if (item.children.length === 0) {
            delete item.children;
          }
        }
        return item.id !== draggedItem.id;
      });
    };

    const incrementVersion = (version) => {
      const parts = version?.split(".");
      if (parts) {
        for (let i = 0; i < parts.length; i++) {
          parts[i] = parseInt(parts[i]);
          if (isNaN(parts[i])) {
            parts[i] = 0; // Default to 0 if parseInt returns NaN
          }
        }
        // Increment the last part
        parts[parts.length - 1]++;
        return parts.join(".");
      }
    };
    const updateChildrenIds = (children, newParentId) => {
      return children.map((child, index) => {
        const newChildId = `${newParentId}.${index + 1}`;
        const updatedChild = { ...child, id: newChildId };
        if (child.children) {
          updatedChild.children = updateChildrenIds(child.children, newChildId);
        }
        return updatedChild;
      });
    };

    const addDraggedItemToTarget = (data, item) => {
      return data.map((parent) => {
        if (parent.id === targetId) {
          setHighlightId(targetId);
          const childrenData = parent?.children || [];
          const newId = incrementVersion(
            childrenData?.[childrenData.length - 1]?.id || "0"
          );
          const newItem = { ...item, id: newId };
          if (item.children) {
            newItem.children = updateChildrenIds(item.children, newId);
          }
          const updatedChildren = [...childrenData, newItem];
          if (updatedChildren?.length > 0) {
            return { ...parent, children: updatedChildren };
          }
          return { ...parent };
        } else if (parent.children) {
          parent.children = addDraggedItemToTarget(parent.children, item);
        } else {
        }
        return parent;
      });
    };

    const dataSourceWithoutDraggedItem = removeDraggedItem(newDataSource);
    const updatedDataSource = addDraggedItemToTarget(
      dataSourceWithoutDraggedItem,
      draggedItem
    );
    setDataSource(updatedDataSource);
    setTimeout(() => {
      getUpdatedSheetData(updatedDataSource);
    }, 1000);
    //
  };
  const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.children) {
      return expanded ? (
        <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
      ) : (
        <RightCircleOutlined onClick={(e) => onExpand(record, e)} />
      );
    }
    return null;
  };

  const handleSave = (newRow) => {
    const findRowAndUpdate = (data, id, update) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          data[i] = { ...data[i], ...update };
          return true;
        }
        if (data[i].children) {
          if (findRowAndUpdate(data[i].children, id, update)) {
            return true;
          }
        }
      }
      return false;
    };
    const newData = [...dataSource];
    if (findRowAndUpdate(newData, newRow?.record.id, newRow?.record)) {
      setDataSource(newData);
      getUpdatedSheetData(newData);
    } else {
      console.log("Row not found.");
    }
  };
  useEffect(() => {
    const newData = [...dataSource];
    setDataSource(newData);
    // getUpdatedSheetData(newData);
  }, []);

  const components = {
    body: {
      row: (props) => <EditableRow {...props} handleDrop={handleDrop} />,
      cell: EditableCell,
    },
  };

  const columns = sheetTableData.table.columns.map((col, index) => {
    if (!col.editable) {
      return { ...col };
    }
    return col
      ? {
        ...col,
        onCell: (record, rowIndex) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          comment: col.noteSummary,
          dataSource: dataSource,
          columnIndex: index,
          rowIndex: rowIndex,
          columns: columns,
          decoderSheetname: decoderSheetname,
          optionMap: optionMap,
          handleSave,
          editAllowed,
        }),
        render: (text, record) => {
          if (col?.dataIndex.startsWith("date")) {
            const index = parseInt(col.dataIndex.replace("date", "")) - 1;
            return convertToUSDString(Number(record?.values?.[index]));
          }

          return text;
        },
      }
      : null;
  });
  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
        rowHoverable={false}
        editAllowed={editAllowed}
        expandable={{
          expandIcon,
          defaultExpandAllRows: true,
          defaultExpandedRowKeys: ["1", "2"],
        }}
        rowKey="id"
        onRow={(record) => ({
          draggable: true,
          onDragStart: (e) => {
            e.dataTransfer.setData("draggedItem", JSON.stringify(record));
          },
          onDragOver: (e) => {
            e.preventDefault();
          },
          onDrop: (e) => handleDrop(e, record.id, record),
        })}
        pagination={false}
        className="decoder-table"
      />
    </div>
  );
};

export default CommanDecoderSheetComponent;
