import React, { useState, useEffect } from "react";
import { convertBinaryFiletoExcel } from "../../../utils/utils";
import apiRequest from "../../../components/api/api";
import Aries from "../../../locale/en-Us.json";
import {
  getspreedSheetApi,
  getProjectList,
  getmetaDataApi,
  getDraftDataApi,
} from "../api";
import { useDispatch } from "react-redux";
const storedUserData = JSON.parse(localStorage.getItem("userData"));

export const useCikNumberList = (stage, subStage) => {
  const dispatch = useDispatch();
  const [cikNumberList, setCikNumberList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCikNumberList = async () => {
      try {
        setLoading(true);
        const result = await getProjectList(stage, subStage, dispatch);
        setCikNumberList(result.projects);
        setCikNumberList(result.projects);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error in GET request:", error);
      }
    };
    fetchCikNumberList();
  }, []);

  return { cikNumberList, loading };
};

export const useGetCikList = (stage, subStage) => {
  const dispatch = useDispatch();
  const [cikNumberList, setCikNumberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchCikNumberList = async () => {
    try {
      setLoading(true);
      const result = await getProjectList(stage, subStage, dispatch);
      setCikNumberList(result.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in GET request:", error);
    }
  };

  return { loading, cikNumberList, fetchCikNumberList };
};

export const useGetSpreedSheetFile = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(
    Aries.VARIENCE_LOADER_GET_SHEET
  );
  const [metaData, setMetaData] = useState(null);

  const getSpreedSheetFile = async ({
    cikNumber,
    apiName,
    projectId,
    ratioSensitivity,
  }) => {
    try {
      setLoading(true);
      setFile(null);
      const result = await getspreedSheetApi(projectId, apiName, dispatch);
      // const data = await convertBinaryFiletoExcel(result);
      if (apiName === "/calculate-ratios") {
        setMetaData(null);
        const metaResult = await getmetaDataApi(
          projectId,
          { type: "RATIOS", ratioSensitivity: ratioSensitivity },
          dispatch
        );
        setMetaData(metaResult);
      }
      // const result = { data, name: result.name };
      setFile(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in GET request:", error);
    }
  };

  return { file, loading, getSpreedSheetFile, metaData, loadingText };
};

export const useGetDraftData = () => {
  const dispatch = useDispatch();
  const [draftData, setDraftData] = useState(null);
  const [draftloading, setDraftloading] = useState(true);
  const [draftMetaData, setdraftMetaData] = useState(null);

  const getAppDraftData = async (projectId, apiName) => {
    try {
      setDraftloading(true);
      const result = await getDraftDataApi(projectId, apiName, dispatch);
      if (apiName === "ratios") {
        setdraftMetaData(null);
        const metaResult = await getmetaDataApi(
          projectId,
          { type: "RATIOS" },
          dispatch
        );
        setdraftMetaData(metaResult);
      }
      setDraftData(result);
      setDraftloading(false);
    } catch (error) {
      setDraftloading(false);
      console.error("Error in GET request:", error);
    }
  };

  return {
    draftData,
    getAppDraftData,
    draftMetaData,
    draftloading,
  };
};

// export const useGetMetaDataInfo = () => {
//    const dispatch = useDispatch();
//    const [metaData, setMetaData] = useState(null);
//    const [loading, setLoading] = useState(true);
//    const [loadingText, setLoadingText] = useState("");
//    const getRatioMetaData = async ({ cikNumber }) => {
//      try {
//        setLoading(true);
//        const metaResult = await getmetaDataApi(projectId, "RATIOS", dispatch);
//        setLoading(false);
//        setMetaData(metaResult);
//      } catch (error) {
//        console.error("Error in GET request:", error);
//      }
//    };

//   return { loadingText, loading, metaData, getRatioMetaData };
// };
