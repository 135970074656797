import { notification } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../redux/reducers";
import { EMPTY_NOTIFICATION_CONFIG, notificationIcons } from "../../constants";
import images from "../../../components/images/images";
import "./notification.scss";

const NotificationComponent = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const notificationState = useSelector((state) => state.notification);

  useEffect(() => {
    if (notificationState.message) {
      openNotification(
        notificationState.type,
        notificationState.message,
        notificationState.description,
        notificationState.placement
      );
    }
  }, [notificationState]);

  const openNotification = (status, title, description, placement) => {
    if (status) {
      api[status]({
        message: <div dangerouslySetInnerHTML={{ __html: title }} />,
        description: description,
        placement: placement,
        duration: 2,
        onClose: () => {
          dispatch(setNotification(EMPTY_NOTIFICATION_CONFIG));
        },
        // icon: <img src={images[notificationIcons[status]]} alt={""} />,
      });
    } else {
      api.open({
        message: <div dangerouslySetInnerHTML={{ __html: title }} />,
        description: description,
        placement: placement,
        duration: 2,
        onClose: () => {
          dispatch(setNotification(EMPTY_NOTIFICATION_CONFIG));
        },
        // icon: <img src={images[notificationIcons[status]]} alt={""} />,
      });
    }
  };

  return <>{contextHolder}</>;
};
export default NotificationComponent;
