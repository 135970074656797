import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const loadingState = useSelector((state) => state.loader);

  return (
    <Spin
      spinning={loadingState.showLoader}
      indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
      fullscreen
      className="fullscreen-loader"
    />
  );
};

export default Loader;
