import React, { useState } from "react";
import { Modal } from "antd";
import "./modelStyle.scss";
import images from "../images/images";
import Aries from "../../locale/en-Us.json";
const ComingSoon = ({ showModel, handleClose }) => {
  const handleCancel = (e) => {
    handleClose();
  };

  return (
    <>
      <Modal
        open={showModel}
        centered
        width={500}
        onCancel={handleCancel}
        footer={[]}
        rootClassName="coming-soon-wrapper"
      >
        <div className="coming-soon-model">
          <img
            className="app-icon"
            src={images["coming_soon.svg"]}
            loading="lazy"
            alt="comming soon icon"
          />
          <p>{Aries.COMINGSOOM_TITLE}</p>
        </div>
      </Modal>
    </>
  );
};
export default ComingSoon;
